<template>
    <div class="previewDetail centerWrapper">
        <div class="mainEle">
            <div class="bulk1">
                <div class="logo">
                    <img :src="websiteLogo+'?t='+(new Date().getTime())" crossorigin="anonymous"/>
                </div>
                <div class="right">
                    <div>网上商城订单</div>
                    <div>订单编号：{{orderDetail.orderSn}}</div>
                </div>
            </div>
            <div class="bulk2">
                <div class="receiveInfo">
                    <div class="name">收货信息</div>
                    <div class="row">
                        <span>收货人</span>
                        <div class="val">{{orderDetail.receiveUser}}</div>
                    </div>
                    <div class="row">
                        <span>联系方式</span>
                        <div class="val">{{orderDetail.telephone}}</div>
                    </div>
                    <div class="row">
                        <span>收货地址</span>
                        <div class="val">{{orderDetail.provinceName+orderDetail.cityName+orderDetail.countyName+orderDetail.address}}</div>
                    </div>
                    <div class="row">
                        <span>期望发货时间</span>
                        <div class="val">{{orderDetail.expectedArrivalDate}}</div>
                    </div>
                </div>
                <div class="orderInfo" v-if="curPage!='purchaseOrder'">
                    <div class="name">订单信息</div>
                    <div class="row">
                        <span>下单时间</span>
                        <div class="val">{{orderDetail.createTime}}</div>
                    </div>
                    <div class="row">
                        <span>支付状态</span>
                        <div class="val">{{['未支付','已支付','部分支付'][orderDetail.payStatus]}}</div>
                    </div>
                    <div class="row" v-if="orderDetail.orderPayList&&orderDetail.orderPayList.length>0">
                        <span>支付方式</span>
                        <div class="val">{{['','线下转账/汇款','支付宝','微信'][orderDetail.orderPayList[0].receiveAccountType]}}</div>
                    </div>
                </div>
                <div class="settleInfo" v-if="curPage=='purchaseOrder'">
                    <div class="name">结算信息</div>
                    <div class="row">
                        <span>订单总金额</span>
                        <div class="val">￥{{orderDetail.amount}}</div>
                    </div>
                    <div class="row">
                        <span>商品总金额</span>
                        <div class="val">￥{{orderDetail.amount}}</div>
                    </div>
                    <div class="row">
                        <span>已付金额</span>
                        <div class="val">￥{{orderDetail.payAmount}}</div>
                    </div>
                    <div class="row">
                        <span>应付金额</span>
                        <div class="val">￥{{orderDetail.shouldReceivedAmount}}</div>
                    </div>
                </div>
            </div>
            <div class="bulk3">
                <div class="viewLine">
                    <div>
                        尊敬的
                        <span>{{orderDetail.receiveUser}}</span>
                        先生/女士，您订购了{{goodsList.length}}件商品，信息如下：
                    </div>
                    <div>订单总金额：￥{{orderTotalPrice}}</div>
                </div>
                <div class="goodsInfo">
                    <div class="thead cell">
                        <div class="goodsPic">商品图片</div>
                        <div class="goodsName">商品名称</div>
                        <div class="goodsSn">商品编号</div>
                        <div class="goodsSpec">规格</div>
                        <div class="goodsPrice">单价</div>
                        <div class="goodsNumber">数量</div>
                        <div class="goodsPay">{{curPage=='purchaseOrder'?'结算金额':'金额'}}</div>
                    </div>
                    <div class="tbody">
                        <div class="item" v-for="(item,i) in goodsList" :key="i">
                            <div class="store">
                                <img src="../../assets/imgs/effect12.png"/>
                                <span class="name">{{item.goods.brandName}}</span>
                                <!-- <span class="add">广东佛山</span> -->
                            </div>
                            <div class="goods cell">
                                <div class="goodsPic">
                                    <img :src="item.goods.cover+'?t='+(new Date().getTime())" crossorigin="anonymous"/>
                                </div>
                                <div class="goodsName">{{item.goods.name}}</div>
                                <div class="goodsSn">{{item.goods.sn}}</div>
                                <div class="goodsSpec">{{item.spec}}</div>
                                <div class="goodsPrice">￥{{item.ourShopPrice}}</div>
                                <div class="goodsNumber">{{item.count}}</div>
                                <div class="goodsPay">￥{{curPage=='purchaseOrder'?item.settlePrice:item.payPrice}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bulk4">
                <div>商品总金额 ￥{{orderTotalPrice}}</div>
                <div>订单总金额 
                    <span>￥{{orderTotalPrice}}</span>
                </div>
            </div>
            <div class="bulk5">
                <div class="left">
                    <div>收款商家：{{orderDetail.receivingMerchant}}</div>
                    <!-- <div>收款银行：</div> -->
                    <div>收款账号：{{receiveAccountList[0].accountNumber}}</div>
                </div>
                <div class="right">
                    <!-- <div class="ali">
                        <div>
                            <img src="../../assets/imgs/alipay.webp"/>
                            <span>亿万用户的选择，更快更安全</span>
                        </div>
                        <div class="qr">
                            <img src="../../assets/imgs/place55.png"/>
                        </div>
                        <div>
                            <i class="iconfont icon-saoma"></i>
                            <div>
                                <div>打开支付宝</div>
                                <div>扫一扫即可付款</div>
                            </div>
                        </div>
                    </div> -->
                    <div class="wx" v-if="receiveAccountList[0].type==3">
                        <div>
                            <img src="../../assets/imgs/wxpay.webp"/>
                            <span>亿万用户的选择，更快更安全</span>
                        </div>
                        <div class="qr qr_0">
                            <!-- <img src="../../assets/imgs/place55.png"/> -->
                            <!-- <img :src="receiveAccountList[0].qrcode"/> -->
                        </div>
                        <div>
                            <i class="iconfont icon-saoma"></i>
                            <div>
                                <div>打开手机微信</div>
                                <div>扫一扫即可付款</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="operate no-print">
            <div class="btn" @click="print">打印</div>
            <div class="btn" @click="printPDF">导出</div>
        </div>
    </div>
</template>

<script>
import {getOrderDetail,getReceiveAccountList} from "@/axios/api"
export default {
    data() {
        return {
            websiteLogo:"",
            orderDetail:{},
            goodsList:[],
            orderTotalPrice:"",
            receiveAccountList:[{}],
            userInfo:{},
            curPage:'',
        }
    },
    methods: {
        gainDetail(){
            getOrderDetail({
                orderId:this.$route.query.orderId||this.afterSaleOrderId,
                userType:this.userInfo.userType
            }).then(res=>{
                console.log("订单详情",res);
                if(res.code==200){
                    this.orderDetail=res.data;
                    this.goodsList=res.data.orderGoodsList;
                    var totalPrice=0;
                    this.goodsList.forEach(item=>{
                        totalPrice+=parseInt(item.count)*parseFloat(item.ourShopPrice);
                        this.$set(item,'payPrice',(parseInt(item.count)*parseFloat(item.ourShopPrice)).toFixed(2))
                    })
                    this.orderTotalPrice=totalPrice.toFixed(2)

                    // this.orderField.forEach(item=>{
                    //     for(var key in res.data){
                    //         if(item.field=="tel_nick"){
                    //             item.val=res.data.user.telephone+"("+(res.data.user.nickName||'')+")"
                    //         }
                    //         if(item.field=="orderStatus"){
                    //             item.val=['未支付','已支付','部分支付'][res.data.payStatus]
                    //         }
                    //         if(item.field=="type_"){
                    //             item.val=['','批发订单','客户订单'][res.data.type]
                    //         }
                    //         if(item.field=='pro_ci_co'){
                    //             item.val=res.data.provinceName+res.data.cityName+res.data.countyName+res.data.address
                    //         }
                    //         if(item.field=='shipStatus'){
                    //             item.val=['未发货','已发货','部分发货'][res.data.shippingStatus]
                    //         }
                    //         if(item.field=='amount+'){
                    //             item.val=`${res.data.amount}(商品总金额)+${res.data.transportAmount}(运费)+${res.data.fragileAmount}(易碎品费)+${res.data.deliveryInstallAmount}(配送安装费)-${res.data.payAmount}(已收款)=${res.data.shouldReceivedAmount}(应收款)`
                    //         }
                    //         if(item.field=='settleAmount+'){
                    //             item.val=`${res.data.settleAmount}(商品结算金额)+${res.data.transportAmount}(运费)+${res.data.fragileAmount}(易碎品费)+${res.data.deliveryInstallAmount}(配送安装费)-0.00(折扣)-0.00(返点)-${res.data.paySettleAmount}(已结算)=${res.data.toBeSettledAmount}(待结算)`
                    //         }
                    //         if(item.field=='settle_status'){
                    //             item.val=['未结算','已结算','部分结算'][res.data.settleStatus]
                    //         }
                    //         if(key==item.field){
                    //             item.val=res.data[key];
                    //             break
                    //         }
                    //     }
                    // })
                    // this.goods_tableData=res.data.orderGoodsList;
                    // this.orderPay_tableData=res.data.orderPayList;
                    // this.settlePay_tableData=res.data.settlePayList;
                    // this.remark_tableData=res.data.orderRemarkList;
                }
            })
        },
        print(){
            // window.print();

            const element = document.querySelector(".mainEle"); // 要转换为PDF的页面元素
            html2canvas(element,{useCORS:true,allowTaint:false}).then((canvas) => {
                var iframe = document.createElement('iframe');
                document.body.appendChild(iframe);
                var doc = iframe.contentWindow.document;
                doc.open();
                doc.write('<html><head><title>Print</title>');
                doc.write('</head><body>');
                // doc.write(html);
                doc.write('</body></html>');
                doc.body.appendChild(canvas);
                doc.close();
                iframe.contentWindow.focus();
                iframe.contentWindow.print();

                setTimeout(()=>{
                    document.body.removeChild(iframe);
                },2000)
            })
        },
        printPDF() {
            const element = document.querySelector(".mainEle"); // 要转换为PDF的页面元素
            html2canvas(element,{useCORS:true,allowTaint:false}).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                // document.body.appendChild(canvas)
                const pdf = new jspdf.jsPDF({
                    orientation: 'portrait',
                    unit: 'px',
                    format: [canvas.width, canvas.height]
                });
                const imgProps= pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save('download.pdf'); // 保存的PDF文件名
            });
        },
        gainAccountList(){
            getReceiveAccountList().then(res=>{
                console.log("收款账户列表",res);
                if(res.code==200){
                    this.receiveAccountList=res.data;
                    this.$nextTick(()=>{
                        this.createQR(res.data[0].qrcode)
                    })
                }
            })
        },
        // 生成二维码
        createQR(url){
            if(this.receiveAccountList[0].type!=3)return
            
            // this.receiveAccountList.forEach((item,i)=>{
            //     new QRCode(document.getElementsByClassName('qr_'+i)[0],{
            //         text:url,
            //         width:76,
            //         height:76,
            //         colorDark:"#000000",
            //         colorLight:"#ffffff"
            //     })
            // })
            var qrcode=new QRCode(document.getElementsByClassName('qr')[0],{
                text:url,
                width:76,
                height:76,
                colorDark:"#000000",
                colorLight:"#ffffff"
            })
        },
    },
    created() {
        if(this.$route.query.c==1){
            this.curPage='purchaseOrder';
        }else{
            this.curPage='';
        }

        if(sessionStorage.websiteLogo){
            this.websiteLogo=sessionStorage.websiteLogo;
        }
        if(localStorage.AOGEYA_userInfo){
            this.userInfo=JSON.parse(localStorage.AOGEYA_userInfo);
        }

        this.gainDetail();
        this.gainAccountList();
    },
    mounted() {
        document.title="订单详情"
    },
}
</script>

<style lang="less" scoped>
.previewDetail{
    height: 100%;
    .mainEle{
        // height: calc(100% - 50px);
        // overflow: auto;
        padding: 30px 30px 0;
        &::-webkit-scrollbar{
            width: 0;
        }
        .bulk1{
            display: flex;
            justify-content: space-between;
            .logo{
                width: 200px;
                &>img{
                    width: 200px;
                    height: 85px;
                    object-fit: cover;
                }
            }
            .right{
                font-size: 30px;
                color: #333;
                font-weight: 700;
                &>div:nth-child(2){
                    margin-top: 5px;
                    font-size: 14px;
                    color: #999;
                }
            }
        }
        .bulk2{
            margin-top: 40px;
            display: flex;
            .receiveInfo,.orderInfo,.settleInfo{
                width: 50%;
                .name{
                    font-size: 16px;
                    color: #333;
                }
                .row{
                    display: flex;
                    align-items: baseline;
                    font-size: 16px;
                    color: #999;
                    margin-top: 12px;
                    &>span{
                        width: 110px;
                    }
                    .val{
                        flex: 1;
                    }
                }
            }
            .orderInfo{
                margin-left: 20px;
            }
        }
        .bulk3{
            margin-top: 50px;
            .viewLine{
                display: flex;
                justify-content: space-between;
                &>div:nth-child(1){
                    font-size: 18px;
                    color: #333;
                    font-weight: 700;
                }
                &>div:nth-child(2){
                    font-size: 18px;
                    color: #666;
                }
            }
            .goodsInfo{
                margin-top: 10px;
                padding-bottom: 30px;
                border-top: 3px solid #a6a6a6;
                border-bottom: 3px solid #a6a6a6;            
                .cell{
                    display: flex;
                    align-items: center;
                    &>div{
                        margin-right: 5px;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                    .goodsPic{
                        width: 150px;
                        // height: 68px;
                    }
                    .goodsName{
                        flex: 2;
                    }
                    .goodsSn{
                        flex: 1;
                    }
                    .goodsSpec{
                        flex: 1
                    }
                    .goodsPrice{
                        width: 120px;
                    }
                    .goodsNumber{
                        width: 80px;
                    }
                    .goodsPay{
                        width: 120px;
                    }
                }
                .thead{
                    height: 60px;
                    border-bottom: 2px solid #a6a6a6;
                    &>div{
                        height: 60px;
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        color: #a8a8a8;
                    }
                }
                .tbody{
                    padding-top: 15px;
                    .item{
                        margin-top: 30px;
                        &:first-child{
                            margin-top: 0;
                        }
                        .store{
                            // padding: 0 15px;
                            display: flex;
                            align-items: center;
                            &>img{
                                width: 18px;
                            }
                            .name{
                                margin: 0 5px;
                                font-size: 14px;
                                font-weight: 700;
                                color: rgba(102, 102, 102, 1);
                            }
                            .add{
                                margin-left: 5px;
                                font-size: 14px;
                                font-weight: 400;
                                color: rgba(153, 153, 153, 1);
                            }
                        }
                        .goods{
                            margin-top: 15px;
                            font-size: 16px;
                            color: #a8a8a8;
                            align-items: stretch;
                            .goodsPic{
                                &>img{
                                    // width: 150px;
                                    // height: 136px;
                                    width: 130px;
                                    max-height: 100px;
                                    object-fit: cover;
                                }
                            }
                            .goodsName{
                                // font-size: 12px;
                                // font-weight: 400;
                                // overflow: hidden;
                                // display: -webkit-box;
                                // -webkit-box-orient: vertical;
                                // -webkit-line-clamp: 3;
                                // line-clamp: 3;
                                line-height: 1.2;
                            }
                            .goodsSn{
                                line-height: 1.2;
                            }
                            .goodsSpec{
                                line-height: 1.2;
                            }
                            .goodsPrice{}
                            .goodsNumber{}
                            .goodsPay{
                                color: #333;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
        .bulk4{
            padding: 40px 0;
            text-align: right;
            border-bottom: 1px solid #e5e5e5;
            &>div:first-child{
                font-size: 16px;
                color: #666;
            }
            &>div:nth-child(2){
                margin-top: 20px;
                font-size: 16px;
                color: #666;
                &>span{
                    font-size: 22px;
                    color: #333;
                    font-weight: 700;
                }
            }
        }
        .bulk5{
            margin-top: 50px;
            margin-bottom: 30px;
            display: flex;
            justify-content: space-between;
            .left{
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 23.17px;
                color: rgba(128, 128, 128, 1);
                &>div{
                    margin-bottom: 40px;
                }
            }
            .right{
                /deep/.wx,.ali{
                    &>div:nth-child(1){
                        display: flex;
                        align-items: center;
                        &>img{
                            width: 150px;
                        }
                    }
                    &>div:nth-child(2){
                        padding-left: 20px;
                        &>img{
                            width: 150px;
                        }
                    }
                    &>div:nth-child(3){
                        margin-top: 20px;
                        padding-left: 15px;
                        display: flex;
                        align-items: center;
                        i{
                            color: green;
                            font-size: 50px;
                        }
                        &>div{
                            font-size: 14px;
                            color: #666;
                        }
                    }
                }
                .ali>div:nth-child(2){
                    margin-top: 10px;
                }
            }
        }
    }
    .operate{
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #e5e5e5;
        .btn{
            width: 100px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #d83030;
            color: #fff;
            font-size: 20px;
            letter-spacing: 5px;
            border-radius: 4px;
            cursor: pointer;
            margin: 0 20px;
            &:first-child{
                background: #fff;
                color: #666;
                border: 1px solid #d83030
            }
        }
    }
}
@media print {
  .no-print {
    display: none;
  }
}
</style>